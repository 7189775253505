<template>
  <b-card-code

    no-body
    title="My Logs"
  >
    <b-table
      responsive
      id="LogTable" 
      :fields="fields"
      :items="items"
      :per-page="perPage"
      ref="table"
      v-if="!loading"
      hover
    >
      <template #cell(index)="data">
          {{ data.index + 1 + perPage * (currentPage - 1) }}
      </template>
      <template #cell(log_message)="data">
      <p v-html="data.item.log_message"></p> 
      </template>
      <template #cell(asset_name)="data">
        <router-link :to="'/assets/scan/' + data.item.asset_id">{{
        data.item.asset_name
        }}</router-link>
      </template>
        <template #cell(activity_time)="data">
            <span >{{ new Date(data.item.activity_time).toLocaleString() }}</span>
        </template>
    </b-table>

    <b-pagination-nav
      v-model="currentPage"
      :numberOfPages="total"
      :total-rows="rows"
      :link-gen="linkGen"
      :per-page="perPage"
      :align="pagination_pos"
      aria-controls="LogTable"
      use-router
      v-if="!loading"
    />
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BTable } from 'bootstrap-vue'
import { BPagination,BPaginationNav, BButton, BBadge,BDropdown, BDropdownItem, BDropdownDivider,  } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BTable,
    BPagination,
    BPaginationNav,
    BButton,
    BBadge,
    BDropdown, BDropdownItem, BDropdownDivider, 
  },
  data() {   
    return {
      pagination_pos: 'center',
      items: [],
      currentPage: 1,
      perPage: 10,
      rows:0,
      total: 1,
      fields: [
        { key: 'index', label: '#' },
        { key: 'log_message', label: 'Log Message' },
        { key: 'activity_on', label: 'Activity on' },
        { key: 'asset_name', label: 'Asset Name' },
        { key: 'ref_id', label: 'Ref ID' },
        { key: 'activity_time', label: 'Activity Time' },
      ],
      loading: false,

    }
  },
  watch: {
    currentPage: function (newVal, oldVal) {
      this.loadLogs(false);
    },
  },

  created: function () {
    this.load();
    this.loadLogs();
  },
  methods: {
    linkGen: function (pageNum) {
      return this.$router.currentRoute.path + "?page=" + pageNum;
    },

    load: function () {
      if (this.$route.query.page) this.currentPage = this.$route.query.page;

    },
    loadLogs: function (reset = true) {
      this.loading = true;
      if (reset) this.currentPage = 1;
      let url =
        process.env.VUE_APP_BASEURL +
        "logs/v1/logs/my-logs?query=true" +
        "&page=" +
        this.currentPage;

      const options = {
        method: "GET",
        headers: { "content-type": "application/json" },
        url: url,
      };

      this.$http(options).then((res) => {
        console.log(res.data ,"mylogssssss")
        console.log(res.data, "rrrrrrrrrrrrr");
        this.items = res.data.results;
        this.rows = res.data.length;
        this.total = Math.ceil(res.data.count / this.perPage);
        this.loading = false;
      });
    },

    
  }
}
</script>